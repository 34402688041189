/**
 *
 * @param {string} postalCode 4 chars and 2 numbers, optionally separated by a space
 * @param {number} houseNumber House number
 * @returns JSON array with the city and street names if successful, or
 */
export default async function getAddress(postalCode, houseNumber)
{
	if (postalCode && houseNumber)
	{
		let paramsObj = {
			postalcode: postalCode,
			number: houseNumber,
		}
		let paramsString = new URLSearchParams(paramsObj)
		paramsString += '&fields=["city","street"]';
		let url = 'https://api.apicheck.nl/lookup/v1/postalcode/nl?'+paramsString;
		let response = await fetch(url,
			{
				headers:
				{
					"X-API-Key": "W0JyIBX6sKT9eRbmZ54adFkSqAfNCLOv"
				}
			}
		);
		let data = await response.json();
		return data;
	}
	else
	{
		console.error("Required parameters not provided.");
		return null;
	}
}