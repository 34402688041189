// This loads jQuery (automatically found in /node_modules/jquery)
global.$ = global.jQuery = require('jquery');
const showDebug = ['live','production'].includes(document.documentElement.dataset.env) ? false : true;

// Loads required libs
import 'jquery-validation';
import "jquery-validation/dist/additional-methods.js"
// import 'magnific-popup';
var mailcheck = require('mailcheck');
// Force upper-case inputs
import { upperCaseInput } from "./helpers/global";
import getAddress from "./modules/apicheck";

const currencyFormatter = new Intl.NumberFormat('nl-NL', {
	style: 'currency',
	currency: 'EUR'
});

/**
 * Check if a variable is a number
 * @param {*} n A variable to check
 * @returns true | false
 */
function isNumber(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}


/**
 * Utility for checking for valid JSON
 * @param {string} text
 * @returns true | false
 */
function isValidJSON(text) {
	try
	{
		JSON.parse(text);
		return true;
	}
	catch
	{
		return false;
	}
}

/**
 * stripHtml
 * @param {string} html String with HTML tags to remove
 * @returns String with HTML tags removed.
 */
function stripHtml(html)
{
	let doc = new DOMParser().parseFromString(html, 'text/html');
	return doc.body.textContent || "";
}

/*
##        #######   ######    ######   #### ##    ##  ######
##       ##     ## ##    ##  ##    ##   ##  ###   ## ##    ##
##       ##     ## ##        ##         ##  ####  ## ##
##       ##     ## ##   #### ##   ####  ##  ## ## ## ##   ####
##       ##     ## ##    ##  ##    ##   ##  ##  #### ##    ##
##       ##     ## ##    ##  ##    ##   ##  ##   ### ##    ##
########  #######   ######    ######   #### ##    ##  ######
*/

	// Save donation information to the log, and optionally send an email
	function recordLog(title, msg, formData, isError = false)
	{
		// URL to POST to
		let url = '/make-gift/log-donation.php';

		// Prep headers
		let headers = new Headers();
		headers.set('Content-Type', 'application/json');

		let fname = formData.get('billing_first_name')
		let lname = formData.get('billing_last_name')
		let email = formData.get('email')
		let frequency = formData.get('frequency')
		let amount = formData.get('amount')
		let payment_method = formData.get('payment_method')


		// Body
		let bodyContent = JSON.stringify({
			title: title,
			message: msg,
			fname: fname,
			lname: lname,
			email: email,
			frequency: frequency,
			amount: amount,
			payment_method: payment_method,
			isError: isError
		})

		// Log message
		fetch(url, {
			method: 'POST',
			headers: headers,
			body: bodyContent
		})
		.catch( () => console.log(error) )
	}


/*
| ######## #### ######## ##       ########
| ##        ##  ##       ##       ##     ##
| ##        ##  ##       ##       ##     ##
| ######    ##  ######   ##       ##     ##
| ##        ##  ##       ##       ##     ##
| ##        ##  ##       ##       ##     ##
| ##       #### ######## ######## ########
|
|  ######  ########    ###    ######## ########
| ##    ##    ##      ## ##      ##    ##
| ##          ##     ##   ##     ##    ##
|  ######     ##    ##     ##    ##    ######
|       ##    ##    #########    ##    ##
| ##    ##    ##    ##     ##    ##    ##
|  ######     ##    ##     ##    ##    ########
*/
/**
 * Update the state of a field
 * @param {('hidden'|'shown'|'required')} mode hidden, shown, or required
 * @param {element} field The field element
 * @param {element} [wrapper] Optional wrapper to show or hide
 */
function updateFieldState(mode, field, wrapper = null)
{
	if (showDebug) console.groupCollapsed(`updateFieldState('${mode}', '#${field.id}')`);
	if (showDebug) console.log({mode});
	if (showDebug) console.log({field});
	if (showDebug) console.log({wrapper});
	switch (mode)
	{
		case 'hidden':
			field.required = false;
			field.disabled = true;
			if (wrapper)
			{
				wrapper.classList.remove('required');
				wrapper.hidden = true;
			}
			break;
		case 'shown':
			field.required = false;
			field.disabled = false;
			if (wrapper)
			{
				wrapper.classList.remove('required');
				wrapper.hidden = false;
			}
			break;
		case 'required':
			field.required = true;
			field.disabled = false;
			if (wrapper)
			{
				wrapper.classList.add('required');
				wrapper.hidden = false;
			}
			break;
		default:
			console.error('Incorrect mode specified');
			console.error({mode});
			break;
	}
	if (showDebug) console.groupEnd();
}

(function ($)
{

	/*
	 * Translated default messages for the jQuery validation plugin.
	 * Locale: DE (German; Deutsch)
	 */
	$.extend( $.validator.messages, {
		required: "Dit is een verplicht veld.",
		remote: "Controleer dit veld.",
		email: "Vul hier een geldig e-mailadres in.",
		url: "Vul hier een geldige URL in.",
		date: "Vul hier een geldige datum in.",
		dateISO: "Vul hier een geldige datum in (ISO-formaat).",
		number: "Vul hier een geldig getal in.",
		digits: "Vul hier alleen getallen in.",
		creditcard: "Vul hier een geldig creditcardnummer in.",
		equalTo: "Vul hier dezelfde waarde in.",
	//     extension: "Vul hier een waarde in met een geldige extensie.",
		maxlength: $.validator.format( "Vul hier maximaal {0} tekens in." ),
		minlength: $.validator.format( "Vul hier minimaal {0} tekens in." ),
		rangelength: $.validator.format( "Vul hier een waarde in van minimaal {0} en maximaal {1} tekens." ),
		range: $.validator.format( "Vul hier een waarde in van minimaal {0} en maximaal {1}." ),
		max: $.validator.format( "Vul hier een waarde in kleiner dan of gelijk aan {0}." ),
		min: $.validator.format( "Vul hier een waarde in groter dan of gelijk aan {0}." ),
		step: $.validator.format( "Vul hier een veelvoud van {0} in." ),

	//     // For validations in additional-methods.js
		iban: "Vul hier een geldig IBAN in.",
		dateNL: "Vul hier een geldige datum in.",
		phoneNL: "Vul hier een geldig Nederlands telefoonnummer in.",
		mobileNL: "Vul hier een geldig Nederlands mobiel telefoonnummer in.",
		postalcodeNL: "Vul hier een geldige postcode in.",
		bankaccountNL: "Vul hier een geldig bankrekeningnummer in.",
		giroaccountNL: "Vul hier een geldig gironummer in.",
		bankorgiroaccountNL: "Vul hier een geldig bank- of gironummer in."
	} );


	$(document).ready(function ()
	{

		// Fadeout the loading
		// $(".loading").fadeOut(200);


		// Handling the payment form
		// if ( $("#payment-form").length )
		let donationForms = document.querySelectorAll('.jsDonationForm');
		donationForms.forEach(donationForm =>
		{
			// UID
			let uid = donationForm.dataset.uidSuffix;
			// Form Settings from Twig / CMS
			let donationFormSettings = allFormSettings['paymentForm'+uid];

			let visibleAmountField = donationForm.querySelector('.jsVisibleAmount');
			let totalAmountField = donationForm.querySelector('.jsTotalAmount');
			let targetAmount = parseFloat( visibleAmountField.value ).toFixed(2);
			let donationBaseElements = donationForm.querySelectorAll('.jsDonationBase');
			let suggestedAmountButtons = donationForm.querySelectorAll('.jsSuggestedAmounts button[value]');
			let globalAmounts = donationForm.querySelector('.jsGlobalAmounts');
			let monthlyAmounts = donationForm.querySelector('.jsMonthlyAmountsOnly');
			let tabMonthly = donationForm.querySelector('.jsTabMonthly');
			let minSingle = visibleAmountField.dataset.minSingle;
			let minMonthly = visibleAmountField.dataset.minMonthly;
			let labelSingle = donationForm.querySelector('.jsLabelSingle');
			let labelMonthly = donationForm.querySelector('.jsLabelMonthly');
			let donationFrequencyText = donationForm.querySelector('.jsDonationFrequencyText');
			let frequencySingle = donationForm.querySelector('.jsFrequencySingle');
			let frequencyMonthly = donationForm.querySelector('.jsFrequencyMonthly');
			let frequencyTabs = donationForm.querySelectorAll('[name=frequency]');
			let paymentMethodFields = donationForm.querySelectorAll('.jsPaymentMethod');
			let paymentIdeal = donationForm.querySelector('.jsPaymentIdeal');
			let paymentCreditcard = donationForm.querySelector('.jsPaymentCreditcard');
			let paymentDirectDebit = donationForm.querySelector('.jsPaymentDirectDebit');
			let selectedPaymentMethodField = donationForm.querySelector('.jsPaymentMethod:checked');
			let billingCountryField = donationForm.querySelector('[name="billing_country"]');
			if (billingCountryField) {
				var billingCountry = billingCountryField.options[billingCountryField.selectedIndex].value;
			}
			let transactionFeesAmountTexts = donationForm.querySelectorAll('.jsTransactionFees b');
			let	transactionFeesCheckbox = donationForm.querySelector('.jsCoverFeesField');
			let	transactionFeesWrapper = donationForm.querySelector('.jsCoverFeesWrapper');
			let formPages = donationForm.querySelectorAll('.jsFormPage');
			let previousPageButtons = donationForm.querySelectorAll('.jsPrevPage');
			let nextPageButtons = donationForm.querySelectorAll('.jsNextPage');
			let mailcheckFields = donationForm.querySelectorAll('.jsMailcheck');
			let processingOverlay = donationForm.querySelector('.jsProcessingOverlay');
			let processingOverlayTitle = processingOverlay.querySelector('.jsOverlayTitle');
			let processingOverlayMessage = processingOverlay.querySelector('.jsOverlayMessage');
			let closeOverlayButtons = donationForm.querySelectorAll('.jsCloseOverlayButton');
			let submitDonationButtons = donationForm.querySelectorAll('.jsSubmitDonationButton');
			// Specific fields
			let donateAsField = donationForm.querySelector('.jsDonateAsField');
			let orgNameWrapper = donationForm.querySelector('.jsOrgNameWrapper');
			let orgNameField = donationForm.querySelector('.jsOrgNameField');
			let selectedOrgType = donationForm.querySelector('.jsSelectedOrgType');
			let directDebitCheckbox = donationForm.querySelector('.jsApproveDirectDebitField');
			let directDebitWrapper = donationForm.querySelector('.jsApproveDirectDebitWrapper');
			let ibanField = donationForm.querySelector('.jsIbanField');
			let ibanWrapper = donationForm.querySelector('.jsIbanWrapper');
			let accountHolderField = donationForm.querySelector('.jsAccountHolderField');
			let accountHolderWrapper = donationForm.querySelector('.jsAccountHolderWrapper');
			let upperCaseInputs = donationForm.querySelectorAll('.jsForceUpperCase');
			let postalCodeField = donationForm.querySelector('.jsPostalCodeField');
			let houseNumberField = donationForm.querySelector('.jsHouseNumberField');
			let additionField = donationForm.querySelector('.jsAdditionField');
			let addressLookupFields = donationForm.querySelectorAll('.jsAddressLookup');
			let streetWrapper = donationForm.querySelector('.jsStreetWrapper');
			let streetField = donationForm.querySelector('.jsStreetField');
			let cityWrapper = donationForm.querySelector('.jsCityWrapper');
			let cityField = donationForm.querySelector('.jsCityField');

			// Init Mailcheck functions
			watchMailcheckFields();
			watchSuggestedEmails();
			watchSuggestedEmailCloseButton();

			// Donation form
			// var $form = $("#payment-form");
			// $(".jsVisibleAmount").focus();
			// $(".jsDonationBase").html(currencyFormatter.format( $('.jsVisibleAmount').val() ));
			function updateSummary()
			{
				donationBaseElements.forEach(el =>
				{
					el.innerHTML = currencyFormatter.format( targetAmount );
				});
			}

			updateSummary();

			/*
 ######  ##     ##  ######    ######   ########  ######  ######## ######## ########
##    ## ##     ## ##    ##  ##    ##  ##       ##    ##    ##    ##       ##     ##
##       ##     ## ##        ##        ##       ##          ##    ##       ##     ##
 ######  ##     ## ##   #### ##   #### ######    ######     ##    ######   ##     ##
	  ## ##     ## ##    ##  ##    ##  ##             ##    ##    ##       ##     ##
##    ## ##     ## ##    ##  ##    ##  ##       ##    ##    ##    ##       ##     ##
 ######   #######   ######    ######   ########  ######     ##    ######## ########

   ###    ##     ##  #######  ##     ## ##    ## ########    ########  ######## ##    ##
  ## ##   ###   ### ##     ## ##     ## ###   ##    ##       ##     ##    ##    ###   ##
 ##   ##  #### #### ##     ## ##     ## ####  ##    ##       ##     ##    ##    ####  ##
##     ## ## ### ## ##     ## ##     ## ## ## ##    ##       ########     ##    ## ## ##
######### ##     ## ##     ## ##     ## ##  ####    ##       ##     ##    ##    ##  ####
##     ## ##     ## ##     ## ##     ## ##   ###    ##       ##     ##    ##    ##   ###
##     ## ##     ##  #######   #######  ##    ##    ##       ########     ##    ##    ##
				Suggested Amount buttons
				-------------------------------------------------------------
			*/
			suggestedAmountButtons.forEach(btn =>
			{
				btn.addEventListener('click', () =>
				{
					suggestedAmountButtons.forEach(btn =>
					{
						btn.classList.remove('selected');
					});
					btn.classList.add('selected');
					visibleAmountField.value  = btn.value;
					visibleAmountField.dispatchEvent(new Event('input', {bubbles:true}));
					visibleAmountField.checkValidity();
				});
			});

			// Highlight any suggested amount buttons if their value matches the visible amount
			function updateSuggestedAmountButtons(baseDonationAmount)
			{
				suggestedAmountButtons.forEach(btn => {
					if (parseFloat(btn.value) == parseFloat(baseDonationAmount))
					{
						btn.classList.add('selected');
					}
					else
					{
						btn.classList.remove('selected');
					}
				});
			}
			// End Suggested Amounts

			/*
 ######  #### ##    ##  ######   ##       ########          ##
##    ##  ##  ###   ## ##    ##  ##       ##               ##
##        ##  ####  ## ##        ##       ##              ##
 ######   ##  ## ## ## ##   #### ##       ######         ##
	  ##  ##  ##  #### ##    ##  ##       ##            ##
##    ##  ##  ##   ### ##    ##  ##       ##           ##
 ######  #### ##    ##  ######   ######## ########    ##

##     ##  #######  ##    ## ######## ##     ## ##       ##    ##
###   ### ##     ## ###   ##    ##    ##     ## ##        ##  ##
#### #### ##     ## ####  ##    ##    ##     ## ##         ####
## ### ## ##     ## ## ## ##    ##    ######### ##          ##
##     ## ##     ## ##  ####    ##    ##     ## ##          ##
##     ## ##     ## ##   ###    ##    ##     ## ##          ##
##     ##  #######  ##    ##    ##    ##     ## ########    ##
				Single/Monthly Donation Options
				-------------------------------------------------------------
			*/

			// Check the single/monthly option and show/hide buttons accordingly.
			function checkSingleMonthlyTab()
			{
				// Store the selected donation frequency
				let selectedFrequency = donationForm.querySelector('input[name="frequency"]:checked').value;
				donationForm.dataset.frequency = selectedFrequency;

				// Monthly
				if ( tabMonthly && tabMonthly.checked )
				{
					visibleAmountField.setAttribute("min", minMonthly);
					// Show the correct amounts label (suggested amounts)
					labelMonthly.hidden = false;
					labelSingle.hidden = true;
					// Are there separate monthly suggested amounts?
					if (monthlyAmounts)
					{
						globalAmounts.hidden = true;
						monthlyAmounts.hidden = false;
					}
					else
					{
						globalAmounts.hidden = false;
					}
					// Show the correct interval label
					donationFrequencyText.innerText = 'maandelijks';

					// Turn off single-only payment methods
					if (paymentIdeal.checked)
					{
						if (showDebug) console.log('Unchecking iDEAL');
						paymentIdeal.checked = false;
						paymentDirectDebit.checked = true;
						handlePaymentMethodChange();
					}
					paymentIdeal.disabled = true;
					paymentIdeal.parentNode.hidden = true;

					// Toggle optional fields
					donationFormSettings.optionalFields.forEach(fld => {
						let wrapper = donationForm.querySelector(fld.wrapper);
						let field = donationForm.querySelector(fld.field);
						updateFieldState(fld.monthly, field, wrapper);
					});
				}
				// Single
				else
				{
					visibleAmountField.setAttribute("min", minSingle);
					// Show the correct amounts label (suggested amounts)
					labelMonthly.hidden = true;
					labelSingle.hidden = false;
					if (monthlyAmounts)
					{
						monthlyAmounts.hidden = true;
					}
					globalAmounts.hidden = false;

					// Show the correct interval label
					donationFrequencyText.innerText = 'eenmalig';

					// Turn on single-only payment methods
					paymentIdeal.disabled = false;
					paymentIdeal.parentNode.hidden = false;

					// Toggle optional fields
					donationFormSettings.optionalFields.forEach(fld => {
						let wrapper = donationForm.querySelector(fld.wrapper);
						let field = donationForm.querySelector(fld.field);
						updateFieldState(fld.single, field, wrapper);
					});
				}
			}

			// On first page load, run the check to take into account hard-coded "Monthly-only" cases.
			checkSingleMonthlyTab();

			// Check if donation meets Freedom Partner requirements
			//fpQualify();

			// Also run the check whenever a tab is changed.
			frequencyTabs.forEach(tab =>
			{
				tab.addEventListener('change', () =>
				{
					// Check if donation meets Freedom Partner requirements
					// fpQualify();
					checkSingleMonthlyTab();
				});
			});
			// End Single/Monthly Donation Options

/*
|    ###    ##     ##  #######  ##     ## ##    ## ########
|   ## ##   ###   ### ##     ## ##     ## ###   ##    ##
|  ##   ##  #### #### ##     ## ##     ## ####  ##    ##
| ##     ## ## ### ## ##     ## ##     ## ## ## ##    ##
| ######### ##     ## ##     ## ##     ## ##  ####    ##
| ##     ## ##     ## ##     ## ##     ## ##   ###    ##
| ##     ## ##     ##  #######   #######  ##    ##    ##
*/
			// When input amount changes
			visibleAmountField.addEventListener('input', (e) =>
			{
				if (showDebug) console.group('Amount field listener');
				// Get provided amount
				targetAmount = parseFloat( visibleAmountField.value ).toFixed(2);

				if ( ! isNaN(targetAmount) )
				{
					if (showDebug) console.info('Syncing amounts.');
					// Keep amount field that actually gets submitted in sync with visible amount
					totalAmountField.value = targetAmount;

					// Update text accordingly
					updateSummary();

					// Calculate Fees
					calculateFees();

					updateSuggestedAmountButtons(targetAmount);
				}
				else
				{
					if (showDebug) console.warn('Amount provided is not a number.');
					totalAmountField.value = null;
				}
				if (showDebug) console.groupEnd();
			});

/*
| ########   #######  ##    ##    ###    ######## ########       ###     ######
| ##     ## ##     ## ###   ##   ## ##      ##    ##            ## ##   ##    ##
| ##     ## ##     ## ####  ##  ##   ##     ##    ##           ##   ##  ##
| ##     ## ##     ## ## ## ## ##     ##    ##    ######      ##     ##  ######
| ##     ## ##     ## ##  #### #########    ##    ##          #########       ##
| ##     ## ##     ## ##   ### ##     ##    ##    ##          ##     ## ##    ##
| ########   #######  ##    ## ##     ##    ##    ########    ##     ##  ######
*/
			// If the Donate As field is set to something other than "Individual", show a field for the name of the organization/church/company.
			donateAsField.addEventListener('change', (e) =>
			{
				if (e.target.value !== 'particulier')
				{
					// Update the label of the Org Name field appropriately
					selectedOrgType.innerHTML = e.target.value;
					// Show the Org Name field
					updateFieldState("shown", orgNameField, orgNameWrapper);
				}
				else
				{
					// Hide the Org Name field
					updateFieldState("hidden", orgNameField, orgNameWrapper);
				}
			});

			// Hide the Org Name field initially
			updateFieldState("hidden", orgNameField, orgNameWrapper);

/*
######## ########     ###    ##    ##  ######     ###     ######  ######## ####  #######  ##    ##
   ##    ##     ##   ## ##   ###   ## ##    ##   ## ##   ##    ##    ##     ##  ##     ## ###   ##
   ##    ##     ##  ##   ##  ####  ## ##        ##   ##  ##          ##     ##  ##     ## ####  ##
   ##    ########  ##     ## ## ## ##  ######  ##     ## ##          ##     ##  ##     ## ## ## ##
   ##    ##   ##   ######### ##  ####       ## ######### ##          ##     ##  ##     ## ##  ####
   ##    ##    ##  ##     ## ##   ### ##    ## ##     ## ##    ##    ##     ##  ##     ## ##   ###
   ##    ##     ## ##     ## ##    ##  ######  ##     ##  ######     ##    ####  #######  ##    ##

######## ######## ########  ######
##       ##       ##       ##    ##
##       ##       ##       ##
######   ######   ######    ######
##       ##       ##             ##
##       ##       ##       ##    ##
##       ######## ########  ######
				Cover Transaction Fees
				--------------------------------------------------------------
*/

			// Calculate Fees based on selected payment method and intended donation amount
			function calculateFees()
			{
				if (showDebug) console.group('calculateFees()');

				// Init vars
				var feesAmnt = 0.00;
				var feesMsg = '';

				// Update which element is selected
				selectedPaymentMethodField = donationForm.querySelector('.jsPaymentMethod:checked');

				if (showDebug) console.info(`Selected payment method: ${selectedPaymentMethodField.value}`);

				// Set a default payment method & calcuate the fees based off the payment method
				switch ( selectedPaymentMethodField.value ?? 'ideal' )
				{
					case 'ideal':
						feesAmnt = 0.25;
						feesMsg = currencyFormatter.format(feesAmnt);
						break;

					case 'creditcard':
						feesAmnt = ( parseFloat(targetAmount) * 0.018 ).toFixed(2);
						feesMsg = currencyFormatter.format(feesAmnt);
						break;

					default:
						feesAmnt = ( parseFloat(targetAmount) * 0.018 ).toFixed(2);
						feesMsg = currencyFormatter.format(feesAmnt);
				}

				// Update elements that show user what the fees amount is
				transactionFeesAmountTexts.forEach(amountText => {
					amountText.innerHTML = feesMsg;
				});

				// If donor selected to cover fees
				if ( transactionFeesCheckbox.checked && isNumber( visibleAmountField.value ) )
				{
					// Apply fees to the total amount field
					applyFees( targetAmount, feesAmnt );
				}
				else
				{
					removeFees( targetAmount );
				}
				if (showDebug) console.groupEnd();
			}

			/**
			 *
			 * @param {Number} targetAmount This is the original amount the
			 * donor intended to give, before covering transaction fees.
			 */
			function applyFees(targetAmount, feesAmount)
			{
				if (showDebug) console.group('applyFees()');
				// Add fees to amount that actually gets submitted
				let newAmount = (parseFloat(targetAmount) + parseFloat(feesAmount)).toFixed(2);

				// Update field that actually gets submitted
				totalAmountField.value = newAmount;
				if (showDebug) console.groupEnd();
			}

			// Reset actual amount to match provided amount
			function removeFees(targetAmount)
			{
				if (showDebug) console.group('removeFees() (or sync to specified amount)');
				totalAmountField.value = targetAmount;
				if (showDebug) console.groupEnd();
			}

			// When donor chooses to cover transaction fees
			transactionFeesCheckbox.addEventListener('change', () =>
			{
				// Calculate Fees
				calculateFees();
			});

			// On first pageload, run once to calculate the fees for the pre-filled in amount
			calculateFees();


			// End Cover Transaction Fees
/*
 ######   #######  ##     ## ##    ## ######## ########  ##    ##
##    ## ##     ## ##     ## ###   ##    ##    ##     ##  ##  ##
##       ##     ## ##     ## ####  ##    ##    ##     ##   ####
##       ##     ## ##     ## ## ## ##    ##    ########     ##
##       ##     ## ##     ## ##  ####    ##    ##   ##      ##
##    ## ##     ## ##     ## ##   ###    ##    ##    ##     ##
 ######   #######   #######  ##    ##    ##    ##     ##    ##
*/

		// When the country dropdown changes
		function handleCountryChanged()
		{
			if (showDebug) console.group('handleCountryChanged()');
			// Get the currently-selected country code
			let country = billingCountryField.options[billingCountryField.selectedIndex].value;
			// Store the regex pattern for the NL postal code format
			let postalCodePattern = '[0-9]{4}\\s?[a-zA-Z]{2}';
			// Check the selected country
			switch (country)
			{
				case "NL":
					// If Netherlands is selected...
					if (showDebug) console.log('Netherlands selected');
					// Apply the pattern for NL postal codes
					postalCodeField.setAttribute('pattern', postalCodePattern);
					// Limit the postal code field length
					postalCodeField.setAttribute('maxlength', 7);
				break;

				default:
					// If some other country is selected...
					if (showDebug) console.log(`${country} selected`);
					// Remove the `pattern` attribute from the postal code field
					postalCodeField.removeAttribute('pattern');
					// Extend the postal code length to accomodate the longer possible global postal codes (10 chars)... and then some.
					postalCodeField.setAttribute('maxlength', 16);
				break;
			}
			// If the postal code or house number fields have a value, recheck to see if it's valid or not under the currently-selected country.
			if (postalCodeField.value) fieldValidityCheck(postalCodeField);
			if (houseNumberField.value) fieldValidityCheck(houseNumberField);

			if (showDebug) console.groupEnd();
		}

		function watchCountry()
		{
			billingCountryField.addEventListener('change', () =>
			{
				handleCountryChanged();
			});
		}

		// Initialize stuff according to the current country
		handleCountryChanged();

		// Watch the country field for changes
		watchCountry();

/*
|    ###    ########  ########  ########  ########  ######   ######
|   ## ##   ##     ## ##     ## ##     ## ##       ##    ## ##    ##
|  ##   ##  ##     ## ##     ## ##     ## ##       ##       ##
| ##     ## ##     ## ##     ## ########  ######    ######   ######
| ######### ##     ## ##     ## ##   ##   ##             ##       ##
| ##     ## ##     ## ##     ## ##    ##  ##       ##    ## ##    ##
| ##     ## ########  ########  ##     ## ########  ######   ######

| ##        #######   #######  ##    ## ##     ## ########
| ##       ##     ## ##     ## ##   ##  ##     ## ##     ##
| ##       ##     ## ##     ## ##  ##   ##     ## ##     ##
| ##       ##     ## ##     ## #####    ##     ## ########
| ##       ##     ## ##     ## ##  ##   ##     ## ##
| ##       ##     ## ##     ## ##   ##  ##     ## ##
| ########  #######   #######  ##    ##  #######  ##
*/
			// Example addresses:
			//     3523 JS, number 31
			//     7331 DL, number 122
			addressLookupFields.forEach(field =>
			{
				field.addEventListener("blur", (e) =>
				{
					// Are required fields filled out, AND is the country set to Netherlands?
					if ( postalCodeField.value && houseNumberField.value && billingCountry == 'NL')
					{
						getAddress(postalCodeField.value, houseNumberField.value)
						.then(results =>
							{
								if (results.error === true)
								{
									console.warn(results.description);
								}
								else
								{
									streetField.value = results.data.street;
									cityField.value = results.data.city;
								}
							}
						);
					}
				});
			});

/*
| ########     ###    ##    ## ##     ## ######## ##    ## ########
| ##     ##   ## ##    ##  ##  ###   ### ##       ###   ##    ##
| ##     ##  ##   ##    ####   #### #### ##       ####  ##    ##
| ########  ##     ##    ##    ## ### ## ######   ## ## ##    ##
| ##        #########    ##    ##     ## ##       ##  ####    ##
| ##        ##     ##    ##    ##     ## ##       ##   ###    ##
| ##        ##     ##    ##    ##     ## ######## ##    ##    ##
|
| ##     ## ######## ######## ##     ##  #######  ########   ######
| ###   ### ##          ##    ##     ## ##     ## ##     ## ##    ##
| #### #### ##          ##    ##     ## ##     ## ##     ## ##
| ## ### ## ######      ##    ######### ##     ## ##     ##  ######
| ##     ## ##          ##    ##     ## ##     ## ##     ##       ##
| ##     ## ##          ##    ##     ## ##     ## ##     ## ##    ##
| ##     ## ########    ##    ##     ##  #######  ########   ######
*/
			function handlePaymentMethodChange()
			{
				// Store the selected payment method
				let selectedPaymentMethod = donationForm.querySelector('input[name="payment_method"]:checked').value;
				donationForm.dataset.paymentMethod = selectedPaymentMethod;

				// Calculate Fees
				calculateFees();

				// Show checkbox for
				if (selectedPaymentMethod == 'direct-debit')
				{
					// Show Direct Debit fields
					updateFieldState('required', directDebitCheckbox, directDebitWrapper);
					updateFieldState('required', ibanField, ibanWrapper);
					updateFieldState('required', accountHolderField, accountHolderWrapper);
					// No transaction fees
					updateFieldState('hidden', transactionFeesCheckbox, transactionFeesWrapper);
				}
				else
				{
					// No Direct Debit fields
					updateFieldState('hidden', directDebitCheckbox, directDebitWrapper);
					updateFieldState('hidden', ibanField, ibanWrapper);
					updateFieldState('hidden', accountHolderField, accountHolderWrapper);
					// Show transaction fees
					updateFieldState('shown', transactionFeesCheckbox, transactionFeesWrapper);
				}
			}

			// Listen for payment method changes
			paymentMethodFields.forEach(paymentMethodField =>
			{
				paymentMethodField.addEventListener('change', () =>
				{
					handlePaymentMethodChange();
				});
			});

			handlePaymentMethodChange();

/*
| ##     ##    ###    ##       #### ########     ###    ######## ####  #######  ##    ##
| ##     ##   ## ##   ##        ##  ##     ##   ## ##      ##     ##  ##     ## ###   ##
| ##     ##  ##   ##  ##        ##  ##     ##  ##   ##     ##     ##  ##     ## ####  ##
| ##     ## ##     ## ##        ##  ##     ## ##     ##    ##     ##  ##     ## ## ## ##
|  ##   ##  ######### ##        ##  ##     ## #########    ##     ##  ##     ## ##  ####
|   ## ##   ##     ## ##        ##  ##     ## ##     ##    ##     ##  ##     ## ##   ###
|    ###    ##     ## ######## #### ########  ##     ##    ##    ####  #######  ##    ##
			Form Validation
			--------------------------------------------------------------
*/

			// Update field classes according to validity
			function fieldValidityCheck(field)
			{
				if (showDebug) console.group(`fieldValidityCheck(${field.name})`);
				// Is the field valid?
				if ( field.checkValidity() )
				{
					if (showDebug) console.log('Valid');
					// If so, remove the `error` class.
					field.classList.remove('error');
					field.closest('fieldset').classList.remove('error');
				}
				else
				{
					if (showDebug) console.log('Invalid');
					// reportValidity() changes focus to the invalid field, so only trigger it if this is the first time this field has become invalid.
					if ( ! field.classList.contains('error') )
					{
						field.reportValidity();
					}
					// If not, add the error class
					field.classList.add('error');
					field.closest('fieldset').classList.add('error');
				}
				if (showDebug) console.groupEnd();
			}

			// Lazily check field validity when they lose focus
			let allFields = donationForm.querySelectorAll('.jsFormPage input, .jsFormPage select, .jsFormPage textarea');
			allFields.forEach(field =>
			{
				field.addEventListener('blur', () =>
				{
					fieldValidityCheck(field);
				});
			});

/*
########  #######  ########  ##     ##    ########     ###     ######   ########  ######
##       ##     ## ##     ## ###   ###    ##     ##   ## ##   ##    ##  ##       ##    ##
##       ##     ## ##     ## #### ####    ##     ##  ##   ##  ##        ##       ##
######   ##     ## ########  ## ### ##    ########  ##     ## ##   #### ######    ######
##       ##     ## ##   ##   ##     ##    ##        ######### ##    ##  ##             ##
##       ##     ## ##    ##  ##     ##    ##        ##     ## ##    ##  ##       ##    ##
##        #######  ##     ## ##     ##    ##        ##     ##  ######   ########  ######
				Form Pages
				--------------------------------------------------------------
*/
			function changePage(pageIdSelector)
			{
				if (showDebug) console.groupCollapsed('changePage()');
				let targetPage = donationForm.querySelector(pageIdSelector);
				if (showDebug) console.log("targetPage: " + pageIdSelector);

				formPages.forEach(formPage =>
				{
					formPage.classList.remove('active');
					formPage.classList.add('inactive');
				});
				targetPage.classList.replace('inactive', 'active');

				if (showDebug) console.warn('Scrolling the form into view');
				donationForm.scrollIntoView({
					behavior: 'smooth'
				});

				// Focus the first form field on the new page
				if (showDebug) console.warn('Focusing the first field on the target form page.');
				targetPage.querySelector('select, textarea, input:not([type=hidden])').focus();

				if (showDebug) console.groupEnd();
			}

			// Previous Page
			previousPageButtons.forEach(prevPageBtn =>
			{
				prevPageBtn.addEventListener('click', (e) =>
				{
					e.preventDefault();
					if (showDebug) console.log("Changing to page " + prevPageBtn.getAttribute('href'));
					changePage( prevPageBtn.getAttribute('href') );
				});
			});

			// Next Page
			nextPageButtons.forEach(nextPageBtn =>
			{
				nextPageBtn.addEventListener('click', (e) =>
				{
					if (showDebug) console.group('Next Page button');

					e.preventDefault();

					let formState = donationForm.checkValidity();

					if (showDebug) console.info({formState});


					let currentPageFields = donationForm.querySelectorAll('.jsFormPage.active input, .jsFormPage.active select, .jsFormPage.active textarea');
					if (showDebug) console.log({currentPageFields});
					var invalidFieldCount = 0;

					currentPageFields.forEach(field =>
					{
						if ( field.checkValidity() )
						{
							// Valid
							field.classList.remove('error');
						}
						else
						{
							// Invalid
							invalidFieldCount++;
							field.classList.add('error');
						}
					});

					if ( invalidFieldCount == 0 )
					{
						if (showDebug) console.log('valid');
						if (showDebug) console.log("Changing to page " + nextPageBtn.getAttribute('href') );
						// Toggle the form pages on/off.
						changePage( nextPageBtn.getAttribute('href') );

					}
					else
					{
						if (showDebug) console.log('invalid');
					}

					if (showDebug) console.groupEnd();
				});
			});
			// End Form Pages

			/**
##     ##    ###    #### ##        ######  ##     ## ########  ######  ##    ##
###   ###   ## ##    ##  ##       ##    ## ##     ## ##       ##    ## ##   ##
#### ####  ##   ##   ##  ##       ##       ##     ## ##       ##       ##  ##
## ### ## ##     ##  ##  ##       ##       ######### ######   ##       #####
##     ## #########  ##  ##       ##       ##     ## ##       ##       ##  ##
##     ## ##     ##  ##  ##       ##    ## ##     ## ##       ##    ## ##   ##
##     ## ##     ## #### ########  ######  ##     ## ########  ######  ##    ##
			 * MailCheck
			 * ------------------------------------------------------------------------
			 */
		function watchMailcheckFields()
		{
			mailcheckFields.forEach(field => {
				let suggestionBox = field.parentNode.querySelector('.jsSuggestedEmailBox');
				let suggestedAddress = suggestionBox.querySelector('.jsSuggestedEmailAddress');
				field.addEventListener('blur', () => {
					mailcheck.run(
					{
						email: field.value,
						suggested: function(suggestion)
						{
							field.classList.add('has-possible-typo');
							suggestedAddress.value = suggestion.full;
							suggestedAddress.innerHTML = `${suggestion.address}@<strong>${suggestion.domain}</strong>`;
							suggestionBox.hidden = false;
						},
						empty: function()
						{
							field.classList.remove('has-possible-typo');
							suggestionBox.hidden = true;
						}
					});
				});
			});
		}

		function watchSuggestedEmails()
		{
			let emailSuggestions = donationForm.querySelectorAll('.jsSuggestedEmailAddress');
			emailSuggestions.forEach(emailSuggestion => {
				let suggestionBox = emailSuggestion.closest('.jsSuggestedEmailBox');
				let emailField = suggestionBox.parentNode.querySelector('.jsMailcheck');
				emailSuggestion.addEventListener('click', () =>
				{
					suggestionBox.hidden = true;
					emailField.value = emailSuggestion.value;
					emailField.classList.remove('has-possible-typo');
					emailSuggestion.closest('form').checkValidity; // ? Untested
				});
			});
		}

		function watchSuggestedEmailCloseButton()
		{
			let suggestedEmailCloseBtns = donationForm.querySelectorAll('.jsSuggestedEmailClose');
			suggestedEmailCloseBtns.forEach(btn => {
				let suggestionBox = btn.closest('.jsSuggestedEmailBox');
				btn.addEventListener('click', () => {
					suggestionBox.hidden = true;
					suggestionBox.parentNode.querySelector('.jsMailcheck').classList.remove('has-possible-typo');
				});
			});
		}
/*
| #### ########     ###    ##    ##
|  ##  ##     ##   ## ##   ###   ##
|  ##  ##     ##  ##   ##  ####  ##
|  ##  ########  ##     ## ## ## ##
|  ##  ##     ## ######### ##  ####
|  ##  ##     ## ##     ## ##   ###
| #### ########  ##     ## ##    ##
*/
		upperCaseInputs.forEach(el => {
			el.addEventListener('input', (el) =>
			{
				upperCaseInput(el.target);
			})
		});


			/**
########   #######  ##    ##    ###    ######## ####  #######  ##    ##
##     ## ##     ## ###   ##   ## ##      ##     ##  ##     ## ###   ##
##     ## ##     ## ####  ##  ##   ##     ##     ##  ##     ## ####  ##
##     ## ##     ## ## ## ## ##     ##    ##     ##  ##     ## ## ## ##
##     ## ##     ## ##  #### #########    ##     ##  ##     ## ##  ####
##     ## ##     ## ##   ### ##     ##    ##     ##  ##     ## ##   ###
########   #######  ##    ## ##     ##    ##    ####  #######  ##    ##
			 * Make Donation
			 * -------------------------------------------------------------
			 */

			closeOverlayButtons.forEach(button =>
			{
				button.addEventListener('click', () =>
				{
					button.closest('.jsProcessingOverlay').hidden = true;
				});
			});



			// AJAX close overlay
			// $("#ajax-close").on("click", function(event)
			// {
			// 	event.preventDefault();
			// 	$("#ajax-response").fadeOut(300);
			// 	$("#ajax-message").hidden = true;
			// 	$(this).hidden = true;
			// 	$("#ajax-processing").hidden = false;
			// });

			function showAJAXMsg(title,msg)
			{
				processingOverlay.classList.remove('has-spinner');
				if (title) processingOverlayTitle.innerHTML = title;
				if (msg) processingOverlayMessage.innerHTML = msg;
			}

			// Process the donation
			function handleDonation(form, submitBtn = "default")
			{
				if (showDebug) console.group('handleDonation()');

				if ( form.checkValidity() )
				{
					if (showDebug) console.log('Form seems valid');

					// Show "Processing" overlay
					processingOverlay.hidden = false;

					// Disable the submit button
					submitDonationButtons.forEach(btn => {
						btn.disabled = true;
					});


					// Submit the form
					const xhr = new XMLHttpRequest();
					const formData = new FormData(form);

					// Debug: The form object
					if(showDebug) console.log("Form Object:");
					if(showDebug) console.log(form);

					// Debug: The form values
					if(showDebug) console.groupCollapsed('Form Values:');
					if(showDebug) console.log(formData.values());
					if(showDebug) {
						for (const value of formData.values()) {
							console.log(value);
						}
					}
					if(showDebug) console.groupEnd();

					xhr.open("POST", "/make-gift/donate.php", true);

					// Send the request
					xhr.send(formData);

					// Listen for `load` event
					xhr.onload = () =>
					{
						if(showDebug) console.group("xhr.onload()");
						// Check response status
						if (xhr.status >= 200 && xhr.status < 300)
						{
							if(showDebug) console.log("xhr.status between 200-299: ["+xhr.status+"]");
							if(showDebug) console.log({xhr});

							// Are the response contents valid JSON?
							if ( isValidJSON(xhr.responseText) )
							{
								if(showDebug) console.log("if ( isValidJSON(xhr.responseText) )");
								const response = JSON.parse(xhr.responseText);
								if(showDebug) console.log({response});
								// Handle the donation response
								// this.handleAppDonateResponse(this, response);
								if ( response.status == "ok" )
								{
									recordLog("Donation successful", JSON.stringify(response), formData, false);
									if ( response.result.redirect_url != "" )
									{
										window.location.replace(response.result.redirect_url);
										// if (showDebug) console.log("Redirection to "+response.result.redirect_url+" has been prevented for testing.");
									}
								}
								else
								{
									// Show the error message
									var errorTitle = response.status +" "+ response.code;
									var errMsg = "";
									if ( response.message )
									{
										if ( isValidJSON(response.message) )
										{
											if(showDebug) console.log("`response.message` is JSON");
											const responseParsed = JSON.parse(response.message);
											errMsg = "<p>[Err1] Er was een probleem:</p>";
											errMsg = errMsg + "<dl>";
											errMsg = errMsg + `<dt>Error:</dt><dd>${responseParsed.error}</dd>`;
											errMsg = errMsg + `<dt>Description:</dt><dd>${responseParsed.error_description}</dd>`;
											errMsg = errMsg + "</dl>";
										}
										else
										{
											if(showDebug) console.log("`response.message` is not JSON");
											errMsg = "<p>[Err2] Er was een probleem: " + response.message + "</p>";
										}
									}
									else
									{
										errMsg = "<p>[Err3] Er is een probleem opgetreden bij het verbinden met de betalingspagina. Probeer het a.u.b. opnieuw.</p>";
									}

									// Generate plaintext version of the error.
									var errMsgText = stripHtml(errMsg);

									// Show the error message in-page.
									showAJAXMsg(errorTitle, errMsg);

									// Output the form data to the console.
									if(showDebug) console.warn({formData});

									// Record the error to the log.
									recordLog(`IJMNL.org donation issue: ${errorTitle}`, errMsgText, formData, true);
								}
							}
						}
						else
						{
							// Compile the error message
							var errMsg = "<p>[Err4] Er was een probleem: " + xhr.status + " " + xhr.statusText + "</p>";

							// Output the returned data to the console.
							if(showDebug) console.log({xhr});

							// Show the error message in-page.
							showAJAXMsg("Error", errMsg);

							// Generate plaintext version of the error.
							var errMsgText = stripHtml(errMsg);

							// Record the error to the log.
							recordLog(`IJMNL.org donation issue: Error`, errMsgText, formData, true);

							// Re-enable the submit button
							submitDonationButtons.forEach(btn => {
								btn.disabled = false;
							});
						}
						if(showDebug) console.groupEnd();
					}
				}
				else
				{
					if (showDebug) console.log('Form isn\'t valid');
				}
				if (showDebug) console.groupEnd();
			}

			donationForm.addEventListener('submit', (event) =>
			{
				if (showDebug) console.group('donationForm.submit(e)');
				event.preventDefault();
				handleDonation(donationForm);
				if (showDebug) console.groupEnd();
			});

		}); // if ( $("#payment-form").length )

	});

/*
##     ##    ###     ######   ##    ## #### ######## ####  ######
###   ###   ## ##   ##    ##  ###   ##  ##  ##        ##  ##    ##
#### ####  ##   ##  ##        ####  ##  ##  ##        ##  ##
## ### ## ##     ## ##   #### ## ## ##  ##  ######    ##  ##
##     ## ######### ##    ##  ##  ####  ##  ##        ##  ##
##     ## ##     ## ##    ##  ##   ###  ##  ##        ##  ##    ##
##     ## ##     ##  ######   ##    ## #### ##       ####  ######

########   #######  ########  ##     ## ########
##     ## ##     ## ##     ## ##     ## ##     ##
##     ## ##     ## ##     ## ##     ## ##     ##
########  ##     ## ########  ##     ## ########
##        ##     ## ##        ##     ## ##
##        ##     ## ##        ##     ## ##
##         #######  ##         #######  ##
*/


/* 		if ( $(".popup").length )
		{
			$(".popup").magnificPopup({
				type: "iframe",
				// Delay in milliseconds before popup is removed
				removalDelay: 300,

				// Class that is added to popup wrapper and background
				// make it unique to apply your CSS animations just to this exact popup
				mainClass: "mfp-fade",
				closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg class="icon icon-close" viewBox="0 0 32 32"><path d="M31.414 28.586l-28-28-2.828 2.828 28 28z"></path><path d="M3.414 31.414l28-28-2.828-2.828-28 28z"></path></svg></button>'
			});
		}

		if ( $(".jsPopupAjax").length )
		{
			$(".jsPopupAjax").magnificPopup({
				type: "ajax",
				// Delay in milliseconds before popup is removed
				removalDelay: 300,

				// Class that is added to popup wrapper and background
				// make it unique to apply your CSS animations just to this exact popup
				mainClass: "mfp-fade",
				closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg class="icon icon-close" viewBox="0 0 32 32"><path d="M31.414 28.586l-28-28-2.828 2.828 28 28z"></path><path d="M3.414 31.414l28-28-2.828-2.828-28 28z"></path></svg></button>'
			});
		}

		if ( $(".jsPopupInline").length )
		{
			$(".jsPopupInline").magnificPopup({
				type: "inline",
				// Delay in milliseconds before popup is removed
				removalDelay: 300,

				// Class that is added to popup wrapper and background
				// make it unique to apply your CSS animations just to this exact popup
				mainClass: "mfp-fade",
				closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg class="icon icon-close" viewBox="0 0 32 32"><path d="M31.414 28.586l-28-28-2.828 2.828 28 28z"></path><path d="M3.414 31.414l28-28-2.828-2.828-28 28z"></path></svg></button>'
			});
		} */

}(jQuery));

// window.onLoadCallback = function()
// {
//     // Donation Captcha
//     window.donationCaptcha = grecaptcha.render('donation-captcha', {
//         sitekey: '6Ldk5d0ZAAAAAPQ2fQEiGzFabmuGihy8ZJUKE0jq',
//         size: "invisible",
//         badge: "inline",
//         callback: onDonationSubmit
//     });
// }

// window.onDonationSubmit = function(response)
// {

//   //   // Trigger submit
//   //   // var response = grecaptcha.getResponse();
//     document.getElementById('donation-captcha-response').value = response;
//     $("#payment-form").trigger('submit');

// }